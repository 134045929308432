<template>
  <div>
    <div class="title-h5 pb-24">
      {{ $t('main.enter_with_email') }}
    </div>
    <!-- <div class="pb-24">{{ $t('main.info_about_auth_key') }}</div> -->
    <router-link :to="{ name: 'auth-password' }">
      <Button transparent>{{
        $t('main.enter_to_cabinet')
      }}</Button></router-link
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped></style>
