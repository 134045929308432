<template>
  <div>
    <div class="title-h5 pb-24">
      {{ $t('main.enter_with_key') }}
    </div>
    <div class="pb-24">{{ $t('main.info_about_auth_key') }}</div>
    <Button transparent @click.native="$router.push({ name: 'auth-key' })">
      {{ $t('main.enter_to_cabinet') }}
    </Button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped></style>
