<template>
  <div class="auth__wrapper">
    <AuthSide />
    <div class="auth__main">
      <AuthHeader />
      <router-view />
      <Loader />
    </div>
  </div>
</template>

<script>
import AuthHeader from '@/components/auth/AuthHeader.vue';
import AuthSide from '@/components/auth/auth_side/AuthSide.vue';

export default {
  components: { AuthHeader, AuthSide },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="sass" scoped>
.auth__wrapper
  background-color: white
  display: flex
  height: inherit
  @include s
    flex-direction: column-reverse

.auth__main
  display: flex
  flex-direction: column
  width: 100%

.auth__main111
  min-height: 8.2rem
  width: 100%
  @include s
    min-height: 5.4rem
  @include xs
    height: auto
  @include xxs
    height: auto

.auth__password
  padding: 6.4rem
  @include s
    padding: 2.4rem
  @include xs
    padding: 1.6rem
  display: flex
  flex-direction: column
  justify-content: center
  background: linear-gradient(345.97deg, #FCFBF2 2.23%, #F9F7E5 39.73%, #E4F0FF 89.49%), #C4C4C4

.auth__back
  display: flex
  justify-content: space-between
  border-bottom: 1px solid $gray
  padding: 1rem 6.4rem
  @include s
    padding-right: 2.4rem
    padding-left: 2.4rem
  @include xs
    padding-right: 1.6rem
    padding-left: 1.6rem

.auth__back-content
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1.6rem
  color: $black

.auth__back-group
  display: flex
  align-items: center
  text-decoration: none
  gap: 1rem
  &:hover
    cursor: pointer
    opacity: .7

.auth__block
  padding: 6.4rem
  margin-bottom: 12rem
  @include s
    padding: 2.4rem
  @include xs
    padding: 1.6rem

.auth__back-arrow
  width: 1.2rem
  height: 1.4rem

.auth__btn-wrapper
  @include xxs
    display: flex
    justify-content: center

.auth__btn-pass
  max-width: 264px
  padding: 14px
  font-size: 16px
  border: 2px solid $black

  @include s
    min-width: 200px
    font-size: 12px
  @include xxs
    align-self: center

// .auth__fields-wrapper
//   margin-bottom: 64px

//   @include s
//     margin-bottom: 40px

// .update__forget-password-title
//   margin-bottom: 24px
//   max-width: 320px

// .update__title
//   margin-bottom: 25px

// .create__title
//   margin-bottom: 75px

// .update__text
//   margin-bottom: 50px
//   max-width: 500px

// .update__sub-title
//   width: 300px
//   margin-bottom: 45px

// .icon-arrow-exit
//   width: 30px
//   height: 15px
//   padding-right: 6px

.auth__question
  padding-bottom: 20px
  max-width: 400px
  @include xxs
    font-size: 24px

.auth__title
  padding-bottom: 40px

  @include xxs
    padding-bottom: 50px
    min-width: 100%

.auth__sub-title
  font-size: 16px
  padding-bottom: 35px
  width: 305px

  @include s
    width: 400px
  @include xxs
    width: 100%

.auth__email
  margin-bottom: 40px

.auth__forgot_pass
  text-decoration: underline
  cursor: pointer
  font-size: 16px
  line-height: 24px

.auth__btn-wrapper
  display: flex
  justify-content: end
  margin-top: 40px
.signature-form__key-file-input
  display: none

.signature-form__key-file-label
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  margin: 22px 0 48px 0
  padding: 11px 30px
  border: 2px dashed $black
  border-radius: 28px
  cursor: pointer
  transition: 0.3s

  &:hover .icon-key
    fill: $black

.signature-form .signature-form__key-file-label
  border-radius: 1px
  color: $black
  padding: 13px 24px
  margin: 0

.signature-form__key-file-label,
.signature-form__key-file-label.disabled
  background-color: transparent

.signature-form__key-file-label.upload-error
  border: 2px dashed $error
  color: $error

.signature-form__key-file-label.upload-success,
.signature-form__key-file-label.upload-error
  &:hover
    background-color: transparent

.signature-form__key-file-label.upload-error
  .icon-key
    fill: $error

.signature-form__key-file-label.disabled
  border: 2px dashed $gray
  color: $gray

  .icon-key
    fill: $gray

.signature-form__key-file-label.upload-success,
.signature-form__key-file-label.disabled
  cursor: default

.signature-form__key-file-error
  font-size: 14px
  color: $error
  margin-top: 12px

.signature-form__key-file-error-text
  color: $black

.signature-form__key-file-info,
.signature-form__key-file-info-left,
.signature-form__key-file-info-right
  display: flex
  align-items: center

.signature-form__key-file-info-left
  max-width: 75%

.signature-form__key-file-info
  justify-content: space-between
  width: 100%

.signature-form__key-file-caption,
.signature-form__key-file-name
// .signature-form__key-file-size
  font-size: 16px
  line-height: 19px

.signature-form__key-file-caption,
.signature-form__key-file-name
  margin-left: 12px

.signature-form__key-file-name
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

// .signature-form__key-file-size
//   color: $gray
//   margin-right: 23px

.signature-form__delete-file-btn
  display: flex
  align-items: center
  cursor: pointer

.icon-certificate
  width: 18px
  height: 20px

.modal__key-file
  display: flex
  flex-direction: column

.modal__key-file-input
  display: none

.modal__key-file-label
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  margin: 16px 0 14px 0
  border: 2px dashed $gray
  cursor: pointer

.modal__key-file-caption,
.modal__key-file-name
  font-size: 16px
  line-height: 19px

.modal__key-file-caption
  margin: 35px 0
  font-size: 12px
  line-height: 14px
  display: flex
  flex-direction: column
  align-items: center

.modal__key-file-name
  color: $gray

.icon-key2
  width: 20px
  height: 20px
  fill: $gray
  margin-bottom: 10px

.auth__key_question
  display: flex
  color: $gray
  justify-content: end

.icon-info_questions
  width: 20px
  height: 20px
  margin-left: 10px

.icon-eye
  fill: $gray
</style>
