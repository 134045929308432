<template>
  <div class="auth__header">
    <div class="auth__back-content">
      <router-link class="auth__back-group" to="/"
        ><svg-icon class="icon-arrow-back" name="arrow-back" />
        <span class="auth__back-content">{{ $t('main.back_main') }}</span>
      </router-link>
    </div>
    <h1 class="auth__header-title">
      {{ getCurrentLoginUserName }}
    </h1>
    <div class="auth__header-space"></div>
  </div>
</template>

<script>
export default {
  computed: {
    getCurrentLoginUserName() {
      return this.$t(`header.btn.${this.$route.name.replace('auth-key-', '')}`)
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="sass" scoped>
.auth__header
  display: flex
  justify-content: space-between
  padding: 1rem 6.4rem
  @include s
    padding-right: 2.4rem
    padding-left: 2.4rem
  @include xs
    padding-right: 1.6rem
    padding-left: 1.6rem
  border-bottom: 1px solid $gray

  &-title
    font-size: 32px
    line-height: 40px
    font-weight: 400
    width: 100%
    text-align: center
    white-space: nowrap

    @include m
      font-size: 24px

    @include xs
      font-size: 18px
      line-height: 32px
      margin-top: -3px
  
  &-space
    width: 100%

    @include xs
      display: none

.auth__back
  display: flex
  justify-content: space-between

.auth__back-content
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1.6rem
  color: $black
  width: 100%
  white-space: nowrap

  @include xs
    font-size: 1.2rem

.auth__back-group
  display: flex
  align-items: center
  text-decoration: none
  gap: 1rem
  &:hover
    cursor: pointer
    opacity: .7
</style>

