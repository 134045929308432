<template>
  <div>
    <router-link class="logo-icon" to="/">
      <svg-icon name="arms" />
    </router-link>
    <!-- <div class="title-h5 pb-24">
      {{ $t('main.enter_with_email') }}
    </div> -->
    <!-- <div class="pb-24">{{ $t('main.info_about_auth_key') }}</div> -->
    <!-- <Button transparent @click.native="goToCredentialsForm">
      {{ $t('main.enter_to_cabinet') }}
    </Button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    goToCredentialsForm() {
      this.$store.commit('RESET_CURRENT_FORM_TYPE')
      this.$router.push({ name: 'auth-password' })
    },
  },
}
</script>

<style lang="sass" scoped></style>

