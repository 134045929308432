<template>
  <div class="auth__side" :class="{ 'auth-side-key': getAuthKeyRoute }">
    <keep-alive>
      <component :is="authSideComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import authSidePassword from '@/components/auth/auth_side/AuthSidePassword.vue'
import authSideKey from '@/components/auth/auth_side/AuthSideKey.vue'
import authSideRestore from '@/components/auth/auth_side/AuthSideRestore.vue'

export default {
  data() {
    return {}
  },
  computed: {
    getAuthKeyRoute() {
      return (
        this.$route.name === 'auth-key-hotels' ||
        this.$route.name === 'auth-key-tour-operator' ||
        this.$route.name === 'auth-key-certification-agency'
      )
    },

    authSideComponent() {
      switch (this.$route.name) {
        case 'auth-password':
          return authSidePassword
        case 'auth-key-hotels':
        case 'auth-key-tour-operator':
        case 'auth-key-certification-agency':
          return authSideKey
        case 'reset-password':
          return authSideRestore
        case 'reset-password':
          return authSideRestore
      }
      return authSidePassword
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped>

.auth__side
  padding: 6.4rem
  max-width: 40rem
  @include s
    max-width: none
    padding: 2.4rem
  @include xs
    padding: 1.6rem
  display: flex
  flex-direction: column
  justify-content: center
  background: linear-gradient(345.97deg, #FCFBF2 2.23%, #F9F7E5 39.73%, #E4F0FF 89.49%), #C4C4C4

.auth-side-key 
  padding: 17px 15px
  justify-content: flex-start
</style>

